<template>
  <v-container fluid>
    <v-icon large color="orange darken-2"> mdi-wrench </v-icon>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
}
</script>
